import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'windi.css'
import web3Config from '@/utils/web3Config.json'
import ERC20ABI from '@/utils/erc20Abi.json'
import axios from 'axios'
import webConfigJson from '../public/webConfig.json'
const app = createApp(App)
const webConfig = webConfigJson["production"]
app.config.globalProperties.$web3Config = web3Config["development"]
app.config.globalProperties.$ERC20ABI = ERC20ABI
app.config.globalProperties.$request = axios.create(webConfig["axios"])
app.use(store).use(router).mount('#app')

