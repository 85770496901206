import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/cp',
    name: 'cp',
    component: () => import(/* webpackChunkName: "about" */ '../views/CompressView.vue')
  }
  ,
  {
    path: '/web3',
    name: 'web3',
    component: () => import(/* webpackChunkName: "about" */ '../views/Web3View.vue')
  }
  ,
  {
    path: '/tron',
    name: 'tron',
    component: () => import(/* webpackChunkName: "about" */ '../views/TronView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
