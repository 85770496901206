import html2canvas from "html2canvas"
// params = { dom: '.save-content', options: { scrollX: 0, scrollY: 0 } }
function DPR() { // 获取设备dpi
  if (window.devicePixelRatio && window.devicePixelRatio > 1) {
    return window.devicePixelRatio;
  }
  return 1;
}

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], {
    type: mimeString
  });
  return blob;

}
async function ImgURL2DataURLv1(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
const imgURL2DataURL = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    }
  });
}
async function imgElement2DataURL(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  var dataURL = canvas.toDataURL("image/png");
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");

}
const html2DataURL = async (params) => {
  return new Promise((resolve) => {
    // params.dom 需要转成图片的dom  css选择器
    let content_html = document.querySelector(params.dom)
    let width = content_html.offsetWidth;
    let height = content_html.offsetHeight
    let canvas = document.createElement("canvas")
    // 获取像素比
    let scaleBy = DPR()
    // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
    canvas.width = width * scaleBy;
    canvas.height = height * scaleBy;
    // 设定 canvas css宽高为 DOM 节点宽高
    canvas.style.width = width + "px"
    canvas.style.height = height + "px"
    // 获取画笔
    // var rect = document.querySelector(params.dom).getBoundingClientRect();
    let context = canvas.getContext("2d")
    // context.translate(-rect.left, -rect.top);
    // 将所有绘制内容放大像素比倍
    // context.scale(scaleBy, scaleBy)
    // context.scale(1, 1)
    var opts = {
      allowTaint: false, //允许加载跨域的图片,默认false不可与useCORS同时存在
      tainttest: true, //检测每张图片都已经加载完成
      scale: scaleBy, // 添加的scale 参数，默认window.devicePixelRatio
      canvas: canvas, //自定义 canvas
      logging: false, //日志开关，发布的时候记得改成false
      // width: width, //dom 原始宽度,试过感觉影响不大
      // height: height, //dom 原始高度,试过感觉影响不大
      useCORS: true,
      ignoreElements: (element) => { //不需要转化的元素
        if (element.id == 'ig') return true
      },
      backgroundColor: null,
      // scrollY: 0,//通过外部调用地方传入
      // y: 0, //通过外部调用地方传入
      // dpi: scaleBy * 3
    };
    // 合并外部自定义参数(不同情况调用可能需设置不同参数)
    if (params.options) {
      opts = Object.assign(opts, params.options)
    }
    console.log(opts)
    html2canvas(content_html, opts).then(canvas => {
      var image = new Image()
      image.setAttribute("crossOrigin", "anonymous")
      // 获取生成的base64图片的url
      var imgUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream")
      image.onload = function () {
        var canvas = document.createElement("canvas")
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext("2d")
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL("image/png")
        // 回调，将生成的图片base64地址传出去，供调用的地方使用图片
        resolve(url)
        // var a = document.createElement("a")
        // var event = new MouseEvent("click")
        // a.download = "图片"
        // a.href = url
        // a.dispatchEvent(event)
      }
      image.src = imgUrl
    })
  })
}

export default {
  html2DataURL,
  imgURL2DataURL,
  imgElement2DataURL,
  dataURItoBlob,
  ImgURL2DataURLv1
}